<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">회원정보 변경</h2>
		</div>
		<form class="form" @submit.prevent="changeinfo">
			<el-input placeholder="이메일" v-model="$store.state.user.profile.email" clearable disabled> </el-input>
			<el-input
				placeholder="기존 비밀번호"
				v-model="password"
				:disabled="!changeinfoLoad"
				show-password
			></el-input>
			<el-input
				placeholder="새 비밀번호"
				v-model="new_password"
				:disabled="!changeinfoLoad"
				show-password
			></el-input>
			<el-input
				placeholder="새 비밀번호 확인"
				v-model="new_password2"
				:disabled="!changeinfoLoad"
				show-password
			></el-input>
			<el-input
				placeholder="이름 또는 사업자명"
				v-model="name"
				:disabled="!changeinfoLoad"
				clearable
			></el-input>
			
			<button class="btn-main btn-full mb-20" :disabled="!changeinfoLoad">{{changeinfoButtonText}}</button>
			<p class="error">{{changeinfoErrorText}}</p>
			
			<br/>
			<hr />
			<div class="signup-wrap">
				<p class="find-password">회원탈퇴는 고객센터로 문의해주세요.</p>
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
export default {
	data() {
		return {
			password: '',
			new_password: '',
			new_password2: '',
			name: this.$store.state.user.profile.name,
			changeinfoLoad: true,
			changeinfoButtonText: "회원정보 변경",
			changeinfoErrorText: ""
		}
	},
	methods: {
		changeinfo(){
			var vm = this;

			if(vm.password == "")
			{
				vm.changeinfoErrorText = "기존 비밀번호를 입력하세요.";
				return;
			}
			if(vm.new_password != "" || vm.new_password2 != ""){
				if(vm.new_password == "")
				{
					vm.changeinfoErrorText = "새 비밀번호를 입력하세요.";
					return;
				}
				if(vm.new_password2 == "")
				{
					vm.changeinfoErrorText = "새 비밀번호 확인을 입력하세요.";
					return;
				}
			}
			if(vm.name == ""){
				vm.changeinfoErrorText = "이름 또는 사용자명을 입력하세요.";
				return;
			}
			if(vm.name.length < 3)
			{
				vm.changeinfoErrorText = "이름 또는 사용자명이 너무 짧습니다.";
				return;
			}
			if(vm.name.length > 30)
			{
				vm.changeinfoErrorText = "이름 또는 사용자명이 너무 깁니다.";
				return;
			}

			var specialRule = /[`~!@#$%^&*|\\'";:/?]/gi;
			if(specialRule.test(vm.name)){
				vm.changeinfoErrorText = "이름 또는 사용자명에 특수문자는 포함될 수 없습니다.";
				return;
			}
			if(vm.new_password != "" || vm.new_password2 != ""){
				if(vm.new_password != vm.new_password2)
				{
					vm.registerErrorText = "새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.";
					return;
				}
				if(vm.new_password.length < 8 || vm.new_password.length > 20)
				{
					vm.registerErrorText = "새 비밀번호는 8~20자로 설정해주세요.";
					return;
				}
			}

			vm.changeinfoErrorText = "";
			vm.changeinfoLoad = false;
			vm.changeinfoButtonText = "처리 중";
			axios.post(apiUrl+'/user/changeinfo',{
				name: this.name,
				password: this.password,
				new_password: this.new_password
			}, { withCredentials: true })
			.then(function(response) {
				if(response.data.success)
				{
					vm.$swal.fire(
						'회원정보가 변경되었습니다.',
						"개인정보 보호를 위해 다시 로그인해주시기 바랍니다.",
						'success'
					).then(() => {
						vm.$router.push("/login");
					} 
					)
				}
				else{
					vm.changeinfoLoad = true;
					vm.changeinfoButtonText = "회원정보 변경";
					vm.changeinfoErrorText = response.data.message;
				}
			})
			.catch(function(error) {
				console.log(error);
			});

			
		}
	},
	beforeCreate(){
		if(!this.$store.state.user.loggedIn)
			this.$router.push("/");
	}
}
</script>

<style scoped>
.signup-wrap {
	padding-top: 20px;
}

.signup-wrap .find-password {
	float: right;
}

.error {
	color: red;
	font-size: 14px;
}

.sns-login-wrap button {
	margin-bottom: 20px;
	border: 1px solid #e5e5e5;
}

.sns-login-wrap .sns-img {
	width: 24px;
	float: left;
	border-radius: 30px;
	overflow: hidden;
}
</style>
